import { Link } from "react-router-dom";
import { Routes } from "../routes";

function Footer() {
  return (
    <footer className="bg-black text-center text-white" style={{ padding: "2rem" }}>
      <div className="text-uppercase d-inline-block" style={{ letterSpacing: 2, fontSize: "0.9rem", maxWidth: 960 }}>
        La banque de problèmes M@ths en-vie - Réalisation : M@ths'n Co / Rémi GILGER
      </div>
      <div className="d-flex flex-wrap column-gap-3 justify-content-center mt-2">
        <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.APPS_LEGAL_NOTICES}>
          Mentions légales
        </Link>
        <p> - </p>
        <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.APPS_PRIVACY}>
          Politique de confidentialité
        </Link>
        <p> - </p>
        <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.APPS_CGU}>
          Conditions Générales d'Utilisation
        </Link>
        <p> - </p>
        <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.APPS_CONTACT}>
          Nous contacter
        </Link>
        <p> - </p>
        <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.APPS_CREDITS}>
          Crédits
        </Link>
      </div>
    </footer>
  );
}

export default Footer;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel } from "../components";
import { faArrowUpRightFromSquare, faChartSimple, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { useAPI } from "../hooks";
import { StatsData, StatsKey } from "./query";
import { useQuery } from "@tanstack/react-query";
import { Accordion } from "react-bootstrap";
import { Routes } from "../routes";

function Home() {
  const api = useAPI();
  const { data: statsData } = useQuery<StatsData>(StatsKey, api.problem.stats, {
    refetchOnReconnect: false,
  });

  return (
    <div className="m-auto position-relative" style={{ maxWidth: 960 }}>
      <div className="text-center">
        {/* <div className="alert alert-info alert-dismissible fade show" role="alert">
          Pour bénéficier d'avantages membres et fonctionnalités exclusives sur nos applications, vous pouvez{" "}
          <a href="https://www.helloasso.com/associations/m-ths-n-co/adhesions/adhesion-2024-2025" rel="noreferrer" target="_blank" className="alert-link">
            adhérer <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
          </a>{" "}
          et rejoindre la communauté. Il suffira ensuite de saisir votre code dans votre profil.
          <button className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div> */}
        <p className="lead fw-bold">Une banque collaborative avec des outils de recherche, d'édition et de vidéoprojection</p>
        <Carousel />
      </div>

      <Accordion className="my-5">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Les enjeux</Accordion.Header>
          <Accordion.Body>
            <div className="d-flex flex-wrap flex-md-nowrap">
              <div className="lead fw-normal">
                <h2 className="text-center fw-bold my-4">Les enjeux</h2>
                <p>
                  Cette plateforme collaborative propose un <span className="fw-bold">répertoire de problèmes, énigmes, photos mathématiques et photo-problèmes</span> à destination des enseignants de
                  la maternelle à la sixième afin de pouvoir&nbsp;:
                </p>
                <ul>
                  <li>
                    conduire des activités ancrées au réel, et ce, dans les trois domaines des mathématiques&nbsp;:{" "}
                    <span className="fst-italic">
                      nombres et calculs, grandeurs et mesures <span className="fst-normal">et</span> espace et géométrie
                    </span>{" "}
                    ;
                  </li>
                  <li>
                    proposer un enseignement explicite et structuré de la résolution de problèmes autour de problèmes prototypiques (basiques), complexes (à étapes), atypiques (de recherche), de
                    proportionnalité et avec des fractions.
                  </li>
                </ul>
              </div>
              <img loading="lazy" className="m-auto" src="img/homeSection1.webp" alt="Les enjeux" width="250" height="355" />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Les usages pédagogiques</Accordion.Header>
          <Accordion.Body>
            <div className="d-flex flex-wrap flex-md-nowrap">
              <img loading="lazy" className="m-auto mb-3 mb-md-auto" src="img/homeSection2.webp" alt="Les usages pédagogiques" width="293" height="557" />
              <div className="lead fw-normal">
                <h2 className="text-center fw-bold mb-3">Les usages pédagogiques</h2>

                <p>Les différentes fonctionnalités vous permettront&nbsp;:</p>
                <ul>
                  <li>
                    de <span className="fw-bold">faire des recherches ciblées</span> sur l'ensemble des contenus&nbsp;;
                  </li>
                  <li>
                    de <span className="fw-bold">soumettre des problèmes</span> à vos élèves via l'outil de vidéoprojection&nbsp;;
                  </li>
                  <li>
                    d'éditer des fiches pour <span className="fw-bold">s'entraîner dans le cadre de séances, plans de travail, ateliers autonomes...</span>
                  </li>
                  <li>
                    de trouver des problèmes pour <span className="fw-bold">différencier</span> votre enseignement&nbsp;;
                  </li>
                  <li>
                    de <span className="fw-bold">proposer des défis ou rallyes mathématiques</span> à partir des énigmes&nbsp;;
                  </li>
                  <li>
                    de pouvoir <span className="fw-bold">interagir avec les autres utilisateurs</span> via notamment les commentaires&nbsp;;
                  </li>
                  <li>
                    de <span className="fw-bold">contribuer à une communauté apprenante</span>.
                  </li>
                </ul>

                <p className="mb-3">
                  Chaque contenu est catégorisé grâce à des mots-clés permettant des recherches ciblées selon le niveau des élèves (maternelle, CP, CE1, CE2, CM1, CM2 et 6ᵉ), la typologie de problèmes
                  et les différentes notions mathématiques.
                </p>

                <p className="mb-3">
                  Il ne s'agit pas d'une méthode, mais d'outils au service de l'enseignant. La banque et le rituel sont en cohérence avec{" "}
                  <a href="https://www.mathsenvie.fr/les-publications/" rel="noreferrer" target="_blank">
                    des ressources clé en main <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
                  </a>{" "}
                  qui s'appuient sur les dernières instructions officielles et notamment les deux guides Eduscol{" "}
                  <a href="https://eduscol.education.fr/document/3738/download" rel="noreferrer" target="_blank">
                    Pour enseigner les nombres, le calcul et la résolution de problèmes au CP <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
                  </a>{" "}
                  et{" "}
                  <a href="https://eduscol.education.fr/document/32206/download" rel="noreferrer" target="_blank">
                    La résolution de problèmes mathématiques au cours moyen <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />.
                  </a>
                </p>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Un commun numérique</Accordion.Header>
          <Accordion.Body>
            <div className="d-flex flex-wrap flex-md-nowrap">
              <div className="lead fw-normal">
                <h2 className="text-center fw-bold my-4">Un commun numérique</h2>

                <p className="mb-3">
                  La banque ne vit que des contributions de ses utilisateurs&nbsp;; <span className="fst-italic">vous y trouverez ce que vous y apporterez !</span> C'est pourquoi, l'usage veut que
                  chaque inscrit propose à la communauté 3 créations personnelles qui lui ouvriront l'accès à l'intégralité des contenus. À noter que toutes les contributions ont été contrôlées et
                  validées par les administrateurs.
                </p>

                <p>
                  La plateforme sur laquelle vous vous trouvez a été élaborée et est maintenue par des bénévoles dans un cadre associatif. Si vous souhaitez participer vous aussi à l'aventure M@ths
                  en-vie ou simplement nous soutenir dans nos différentes actions,{" "}
                  <a href="https://www.mathsenvie.fr/association" rel="noreferrer" target="_blank">
                    cliquez ici <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />.
                  </a>
                </p>
              </div>
              <div className="m-auto text-center">
                <img loading="lazy" className="mt-3 mt-md-auto ms-1" src="img/homeSection3.webp" alt="Creative Commons licenses : CC BY-NC-SA" width="300" height="191" />

                <p {...{ "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:dct": "http://purl.org/dc/terms/" }}>
                  <a {...{ property: "dct:title" }} rel="cc:attributionURL" href={Routes.HOME}>
                    Banque de problèmes M@ths en-vie
                  </a>{" "}
                  by <span {...{ property: "cc:attributionName" }}>M@ths'n Co.</span> is licensed under{" "}
                  <a className="d-inline-block" href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">
                    CC BY-NC-SA 4.0
                  </a>
                  <img
                    loading="lazy"
                    className="align-text-bottom ms-1"
                    alt="Creative Commons CC icon"
                    width="22"
                    height="22"
                    src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
                  />
                  <img
                    alt="Creative Commons BY icon"
                    className="align-text-bottom ms-1"
                    loading="lazy"
                    width="22"
                    height="22"
                    src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"
                  />
                  <img
                    alt="Creative Commons NC icon"
                    className="align-text-bottom ms-1"
                    loading="lazy"
                    width="22"
                    height="22"
                    src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1"
                  />
                  <img
                    alt="Creative Commons SA icon"
                    className="align-text-bottom ms-1"
                    loading="lazy"
                    width="22"
                    height="22"
                    src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1"
                  />
                </p>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="d-flex flex-md-row flex-column mx-auto justify-content-between row-gap-4 align-items-center" style={{ maxWidth: 960 }}>
        <div className="d-flex flex-row">
          <FontAwesomeIcon className="bg-secondary p-2 rounded-4 rounded-end-0 text-white align-self-center" size="4x" icon={faChartSimple} />
          <div className="d-flex flex-column px-3 rounded-5 mw-100" style={{ backgroundColor: "#D8D8D8", height: 140, width: "fit-content" }}>
            <p style={{ height: 60, lineHeight: "80px" }}>
              Nombre de contributions&nbsp;:&nbsp;<span className="fw-bold">{statsData?.totalContributions}</span>
            </p>
            <p style={{ height: 60, lineHeight: "60px" }}>
              Nombre de contributeurs&nbsp;:&nbsp;<span className="fw-bold">{statsData?.numberOfContributors}</span>
            </p>
          </div>
        </div>
        <div className="d-flex flex-row">
          <FontAwesomeIcon className="bg-secondary p-2 rounded-4 rounded-end-0 text-white align-self-center" style={{ left: -88, top: "calc(50% - 40px)" }} size="4x" icon={faTrophy} />
          <div className="p-3 rounded-5 mw-100" style={{ backgroundColor: "#D8D8D8", minHeight: 180, width: "fit-content" }}>
            <p className="mb-1">Top 10 des contributeurs&nbsp;:</p>
            <div className="d-flex flex-md-row flex-column">
              <ol className="mb-0">
                {statsData?.topUsers.slice(0, 5).map((user) => (
                  <div key={user.username} className="d-flex flex-column">
                    <li>
                      <span className="fw-bold">{user.username}</span>&nbsp;(<span>{user.nbProblem}</span>)
                    </li>
                  </div>
                ))}
              </ol>
              <ol className="mb-0" start={6}>
                {statsData?.topUsers.slice(5, 10).map((user) => (
                  <div key={user.username} className="d-flex flex-column">
                    <li>
                      <span className="fw-bold">{user.username}</span>&nbsp;(<span>{user.nbProblem}</span>)
                    </li>
                  </div>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import axios from "axios";
import { getPostPutAxiosInstance, handleError } from "./utils";

class ConsoleAPI {
  async confirmUser(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/console/confirm-user", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async cacheClear() {
    try {
      const response = await getPostPutAxiosInstance().put("/console/cache-clear");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async executeSql() {
    try {
      const response = await getPostPutAxiosInstance().put("/console/sql");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default ConsoleAPI;

import { useQueryClient } from "@tanstack/react-query";
import { ProblemType } from "../../types";
import { GenerateAiTypeEnum, ResponseStateEnum } from "../../Utils";

const AdminDataKey: [base: string] = ["admin"];

const AdminReviewProblemKey: [base: string, action: string] = [...AdminDataKey, "review"];
const AdminReviewProblemIndexKey = (index: number): [base: string, action: string, index: number] => [...AdminReviewProblemKey, index];

const AdminChartsKey: [base: string, action: string] = [...AdminDataKey, "charts"];

const AdminLastCommentsKey: [base: string, action: string] = [...AdminDataKey, "lastComment"];

const AdminProblemsAiKey = (type: GenerateAiTypeEnum) => [...AdminDataKey, "ia", "problems", type];

export type AdminData = {
  nbrProblemNotReviewed: number;
  numberOfVisit: number;
};

export type AdminReviewProblem = {
  nbrProblemNotReviewed: number;
  message?: string;
  problem?: ProblemType;
  prev?: number;
  next?: number;
};

export type AdminChartsData = {
  labels: string[];
  data: {
    visits: number[];
    user_visits: number[];
    emails_sent: number[];
    registrations: number[];
    rituel_clicks: number[];
    problems_added: number[];
    games_played: number[];
  };
};

export type AdminGenerateAIData = {
  response: string;
  responseState: ResponseStateEnum;
  responseSentence: string;
  helpSentence1: string;
  helpSentence2: string;
  responseExplication: string;
};

const useAdminQuery = () => {
  const queryClient = useQueryClient();

  const updateNumberProblmesToReview = (nbrProblemNotReviewed: number) => {
    queryClient.setQueryData<AdminData>(AdminDataKey, (oldData) =>
      oldData
        ? {
            ...oldData,
            nbrProblemNotReviewed: nbrProblemNotReviewed,
          }
        : oldData
    );
  };

  return { updateNumberProblmesToReview };
};

export { useAdminQuery };

export { AdminDataKey, AdminProblemsAiKey, AdminLastCommentsKey, AdminReviewProblemKey, AdminReviewProblemIndexKey, AdminChartsKey };

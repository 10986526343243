import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { ApiError, useAPI } from "../hooks";
import { useModals } from "../contexts/modals";
import { ModalTypeEnum } from "../Utils";
import { MutationData } from "../types";

function Console() {
  const api = useAPI();
  const { openModal } = useModals();

  const cacheClear = useMutation<MutationData, ApiError>({
    mutationFn: () => {
      executeSql.reset();
      return api.console.cacheClear();
    },
  });

  const executeSql = useMutation<MutationData, ApiError>({
    mutationFn: () => {
      cacheClear.reset();
      return api.console.executeSql();
    },
  });

  return (
    <>
      {cacheClear.isSuccess && <div>{cacheClear.data.message}</div>}
      {executeSql.isSuccess && <div>{executeSql.data.message}</div>}
      {cacheClear.isError && <div>{cacheClear.error.message}</div>}
      {executeSql.isError && <div>{executeSql.error.message}</div>}
      {(cacheClear.isLoading || executeSql.isLoading) && (
        <div>
          Execution en cours <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />
        </div>
      )}
      <div className="mt-3">
        <button className="btn btn-danger me-1" onClick={() => cacheClear.mutate()}>
          Clear the cache
        </button>
        <button className="btn btn-danger me-1" onClick={() => executeSql.mutate()}>
          Execute sql command
        </button>
        <button className="btn btn-primary" onClick={() => openModal(ModalTypeEnum.ConfirmUser)}>
          <FontAwesomeIcon icon={faCheck} /> Accepter un utilisateur
        </button>
      </div>
    </>
  );
}

export default Console;
